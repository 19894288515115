import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import UploadForm from '@/core/components/common/forms/upload/Upload.vue';
import { CERTIFICATES_STORE } from '@/store/modules/certificates';
export default defineComponent({
    name: 'accreditation-form',
    emits: ['on-update-value', 'on-taggle-form'],
    props: ['value'],
    components: {
        UploadForm
    },
    data() {
        return {
            form: {
                type: '',
                membershipNumber: '',
                url: '',
                // postcode: '',
                attachments: []
            },
            attachmentSources: [],
            options: [],
        };
    },
    watch: {
        form: {
            immediate: true,
            deep: true,
            handler(value) {
                this.validateForm(value);
            }
        }
    },
    async created() {
        if (!this.certificates.length) {
            await this.getCertificates();
        }
        if (this.certificates.length) {
            this.options = this.certificates.map((item) => {
                const { id, name } = item;
                return { value: id, label: name };
            });
        }
    },
    computed: {
        ...mapGetters(CERTIFICATES_STORE, [
            'certificates',
        ]),
    },
    methods: {
        ...mapActions(CERTIFICATES_STORE, ['getCertificates']),
        validateForm(data) {
            let value = data;
            let isValid = false;
            const fields = Object.keys(data);
            if (fields.length && data.type) {
                isValid = true;
                this.$emit('on-update-value', value);
            }
            // if (fields.length) {
            //   fields.forEach((field) => {
            //     if (!data[field]) {
            //       isValid = false;
            //     }
            //   });
            // }
            if (!isValid) {
                value = {};
            }
            this.$emit('on-update-value', value);
        },
        onToggleForm() {
            this.$emit('on-taggle-form');
        }
    }
});

import { defineComponent } from 'vue';
export default defineComponent({
    name: 'profile-address-dialog',
    emits: ['on-update-value'],
    props: ['value'],
    data() {
        return {
            form: {
                businessAddress: '',
                businessPostcode: '',
                preferredDistance: ''
            }
        };
    },
    watch: {
        form: {
            immediate: true,
            deep: true,
            handler(value) {
                this.validateForm(value);
            }
        }
    },
    created() {
        const { value } = this;
        if (value) {
            this.form = { ...value };
        }
    },
    methods: {
        validateForm(data) {
            // let value = data;
            // let isValid = true;
            // const fields = Object.keys(data);
            // if (fields.length) {
            //   fields.forEach((field) => {
            //     if (!data[field]) {
            //       isValid = false;
            //     }
            //   });
            // }
            // if (!isValid) {
            //   value = {};
            // }
            this.$emit('on-update-value', data);
        }
    }
});

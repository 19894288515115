import { defineComponent } from 'vue';
export default defineComponent({
    name: 'profile-description-dialog',
    emits: ['on-update-value'],
    props: {
        value: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            form: { description: '' }
        };
    },
    watch: {
        form: {
            deep: true,
            immediate: true,
            handler(value) {
                this.$emit('on-update-value', value);
            }
        }
    },
    created() {
        this.form.description = this.value;
    },
    methods: {
        onBlur() {
            // console.log('onBlur', this.form.description);
        }
    }
});

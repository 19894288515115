import { defineComponent } from 'vue';
import CategoryForm from '@/core/components/common/forms/category/CategoryForm.vue';
export default defineComponent({
    name: 'profile-expertise-dialog',
    emits: ['on-update-value'],
    components: {
        CategoryForm
    },
    props: ['value'],
    data() {
        return {
            categories: []
        };
    },
    created() {
        const { value } = this;
        if (value && value.length) {
            console.log(value, 'passed value');
            this.categories = value;
        }
    },
    methods: {
        getData(categories) {
            const skills = [];
            let data = {};
            for (const category of categories) {
                const { subCategories } = category;
                for (const subCategory of subCategories) {
                    const { id } = subCategory;
                    if (!skills.includes(id)) {
                        skills.push(id);
                    }
                }
            }
            if (skills.length) {
                data = { subCategoryId: skills };
            }
            this.$emit('on-update-value', data);
        },
    }
});

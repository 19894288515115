import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
// import GridUpload from '@/core/components/common/forms/upload/GridUpload.vue';
import UploadForm from '@/core/components/common/forms/upload/Upload.vue';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    components: {
        // GridUpload,
        UploadForm
    },
    emits: ['on-update-value', 'on-progress-upload'],
    data() {
        return {
            form: {},
            hasUpload: false,
            attachmentSources: [],
        };
    },
    watch: {
        form: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value.attachments?.length) {
                    this.onUpdateValue(value.attachments);
                }
            }
        }
    },
    created() {
        this.initialize();
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
    },
    methods: {
        initialize() {
            if (this.user.userPublicProfile?.galleries?.length) {
                this.attachmentSources = this.user.userPublicProfile?.galleries;
            }
        },
        onProgressUpload() {
            this.$emit('on-progress-upload');
        },
        onUpdateValue(attachments) {
            this.$emit('on-update-value', attachments);
        }
    }
});

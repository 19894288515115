import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import ProfileAccreditationDialog from '@/modules/profile/components/dialog/ProfileAccreditationDialog.vue';
import ProfileAddressDialog from '@/modules/profile/components/dialog/ProfileAddressDialog.vue';
import ProfileDescriptionDialog from '@/modules/profile/components/dialog/ProfileDescriptionDialog.vue';
import ProfileExpertiseDialog from '@/modules/profile/components/dialog/ProfileExpertiseDialog.vue';
import ProfileGalleryDialog from '@/modules/profile/components/dialog/ProfileGalleryDialog.vue';
import ProfileInsuranceDialog from '@/modules/profile/components/dialog/ProfileInsuranceDialog.vue';
import ProfileSocialDialog from '@/modules/profile/components/dialog/ProfileSocialDialog.vue';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'profile-base-dialog',
    emits: ['close', 'on-submit', 'on-delete'],
    components: {
        ProfileAccreditationDialog,
        ProfileAddressDialog,
        ProfileDescriptionDialog,
        ProfileInsuranceDialog,
        ProfileExpertiseDialog,
        ProfileSocialDialog,
        ProfileGalleryDialog
    },
    props: ['customLabel', 'type', 'value', 'openDialog', 'hasRequested'],
    watch: {
        hasRequested: {
            immediate: true,
            handler() {
                // if (value) {
                this.isSubmitting = false;
                this.isReadyToSubmit = false;
                // }
            }
        },
    },
    data() {
        return {
            dialogFormVisible: false,
            isSubmitting: false,
            isReadyToSubmit: false,
            formValue: {},
            forceDisableBtn: false
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, [
            'user',
            'saveUserProfile'
        ]),
        formType() {
            return this.type.toLowerCase();
        }
    },
    created() {
        this.dialogFormVisible = this.openDialog;
        if (this.type === 'Gallery') {
            this.forceDisableBtn = true;
        }
    },
    methods: {
        resetFormData() {
            this.isReadyToSubmit = false;
            this.formValue = {};
        },
        onSubmit() {
            if (this.isReadyToSubmit) {
                this.isSubmitting = true;
                this.$emit('on-submit', this.formValue);
            }
            else {
                this.$emit('close');
            }
        },
        onUpdateValue(value) {
            const { formType } = this;
            let hasValue = value;
            const isMany = Array.isArray(value);
            const isObject = typeof value === 'object';
            if (isMany && value.length) {
                hasValue = value.length;
            }
            if (isObject) {
                hasValue = Object.keys(value).length;
            }
            if (hasValue) {
                this.formValue[formType] = value;
                this.isReadyToSubmit = true;
                this.forceDisableBtn = false;
            }
            else {
                this.resetFormData();
            }
        },
        onDeleteValue(id) {
            const { formType } = this;
            this.formValue[formType] = id;
            this.isReadyToSubmit = true;
            this.isSubmitting = true;
            this.$emit('on-delete', this.formValue);
        },
        onProgressUpload() {
            this.forceDisableBtn = true;
        },
        onDialogClose() {
            if (!this.isSubmitting) {
                this.$emit('close');
            }
        },
    }
});

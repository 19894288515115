import { CirclePlusFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
// import ProfileVerifiedIcon from '@/modules/profile/components/ProfileVerifiedIcon.vue';
export default defineComponent({
    name: 'accreditation-detail',
    props: ['value'],
    emits: ['on-taggle-form', 'on-delete-value'],
    components: {
        // ProfileVerifiedIcon
        CirclePlusFilled
    },
    data() {
        return {
            userCertificates: []
        };
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler() {
                this.initialize();
            }
        },
    },
    created() {
        this.initialize();
    },
    methods: {
        initialize() {
            const { value } = this;
            if (value && value.length) {
                this.userCertificates = value.map((item) => {
                    const { id, certificate, userVerificationStatus, metadata } = item;
                    return {
                        id,
                        name: certificate.name,
                        isVerified: userVerificationStatus === UserVerificationStatusEnum.VERIFIED,
                        registrationNumber: metadata?.registrationNumber,
                        url: metadata?.url
                    };
                });
            }
        },
        onDeleteValue(id) {
            this.$emit('on-delete-value', id);
        },
        onToggleForm() {
            this.$emit('on-taggle-form');
        }
    }
});

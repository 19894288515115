import { defineComponent } from 'vue';
import InsuranceDetail from '@/core/components/common/forms/insurance/InsuranceDetail.vue';
import InsuranceForm from '@/core/components/common/forms/insurance/InsuranceForm.vue';
export default defineComponent({
    name: 'profile-insurance-dialog',
    emits: ['on-update-value', 'on-delete-value'],
    props: ['value', 'hasRequested'],
    components: {
        InsuranceDetail,
        InsuranceForm
    },
    data() {
        return {
            showForm: false,
        };
    },
    watch: {
        hasRequested: {
            immediate: true,
            handler() {
                this.showForm = false;
            }
        },
    },
    computed: {
        ifHasNoValueOnFirstLoad() {
            if (!this.showForm && this.value.length <= 0) {
                return true;
            }
            return false;
        }
    },
    methods: {
        onToggleForm() {
            this.showForm = !this.showForm;
        },
        onUpdateValue(data) {
            this.$emit('on-update-value', data);
        },
        onDeleteValue(id) {
            this.$emit('on-delete-value', id);
        }
    }
});
